import { print } from "graphql";
import { GraphQLClient } from "graphql-request";
import { checkStaleSession } from "../util";
import config from "../config.json";

import MenuQuery from "./queries/MenuQuery.gql";
import ProductQuery from "./queries/ProductQuery.gql";
import RetailerQuery from "./queries/RetailerQuery.gql";
import RetailersQuery from "./queries/RetailersQuery.gql";
import UpdateCheckout from "./queries/UpdateCheckout.gql";
import CreateCheckout from "./queries/CreateCheckout.gql";
import GetSpecialsList from "./queries/GetSpecialsList.gql";
import MenuByStaffPicks from "./queries/MenuByStaffPicks.gql";
import FetchCartDetails from "./queries/FetchCartDetails.gql";
import AddItemToCheckout from "./queries/AddItemToCheckout.gql";
import UpdateItemQuantity from "./queries/UpdateItemQuantity.gql";
import OrdersByOrderNumber from "./queries/OrdersByOrderNumber.gql";
import MenuByCustomSection from "./queries/MenuByCustomSection.gql";
import MenuBySpecialsSection from "./queries/MenuBySpecialsSection.gql";
import RemoveItemFromCheckout from "./queries/RemoveItemFromCheckout.gql";

const { apiEndpoint, multiKeyConfig } = rangeHeadlessApi;

function getMultiKeyRequestIndex(variables) {
    let keyIndexHeader = null;

    if (!multiKeyConfig) {
        return keyIndexHeader;
    }

    if (!Array.isArray(multiKeyConfig)) {
        return keyIndexHeader;
    }

    if (!variables || !variables.hasOwnProperty('retailerId')) {
        return keyIndexHeader;
    }
    
    multiKeyConfig.forEach((retailerArr, i) => {
        // Skip if retailerArr is null or not an array
        if (!retailerArr || !Array.isArray(retailerArr)) {
            return; // This 'return' in forEach acts like 'continue' in a normal loop
        }

        retailerArr.forEach((retailer) => {
            if (retailer && retailer.id === variables.retailerId) {
                keyIndexHeader = i;
            }
        });
    });

    return keyIndexHeader;
}

function processCategoryFilter(variables) {
    if (variables && variables.menuFilter && Array.isArray(variables.menuFilter.category)) {
        if (variables.menuFilter.category.length === 1) {
            variables.menuFilter.category = variables.menuFilter.category[0];
        } else if (variables.menuFilter.category.length > 1) {
            variables.menuFilter.categories = variables.menuFilter.category;
            delete variables.menuFilter.category;
        } else {
            delete variables.menuFilter.category;
        }
    }
    return variables;
}

const dutchieQueryClient = new GraphQLClient(apiEndpoint, {
  errorPolicy: "all",
});

const dutchieMutationClient = new GraphQLClient(apiEndpoint);

export async function dutchieQuery(queryName, variables) {
    if (checkStaleSession()) return;
    let requestHeaders = null;
    if (queryName === "OrdersByOrderNumber") {
        requestHeaders = {
            "x-rh-order": "true",
        };
    }

    const keyIndexHeader = getMultiKeyRequestIndex(variables);

    if (keyIndexHeader !== null) {
        requestHeaders = requestHeaders || {};
        requestHeaders["x-rh-keyindex"] = keyIndexHeader.toString();
    }

    // Process the category filter
    variables = processCategoryFilter(variables);

    return await dutchieQueryClient.rawRequest(
        print(getQuery(queryName)),
        variables,
        requestHeaders
    );
}

export async function dutchieMutation(queryName, variables) {
  if (checkStaleSession()) return;

    let requestHeaders = null;
    const keyIndexHeader = getMultiKeyRequestIndex(variables);

    if (keyIndexHeader !== null) {
        requestHeaders = {
            "x-rh-keyindex": keyIndexHeader.toString()
        };
    }

    return await dutchieMutationClient.request(
        print(getQuery(queryName)),
        variables,
        requestHeaders
    );
}

/**
 * Get GQL query from string.
 * @param {string} queryName
 * @returns
 */

const getQuery = (queryName) => {
  switch (queryName) {
    case "RetailerQuery":
      return RetailerQuery;
    case "RetailersQuery":
      return RetailersQuery;
    case "FetchCartDetails":
      return FetchCartDetails;
    case "CreateCheckout":
      return CreateCheckout;
    case "GetSpecialsList":
      return GetSpecialsList;
    case "RemoveItemFromCheckout":
      return RemoveItemFromCheckout;
    case "UpdateItemQuantity":
      return UpdateItemQuantity;
    case "AddItemToCheckout":
      return AddItemToCheckout;
    case "MenuByStaffPicks":
      return MenuByStaffPicks;
    case "MenuBySpecialsSection":
      return MenuBySpecialsSection;
    case "MenuQuery":
      return MenuQuery;
    case "ProductQuery":
      return ProductQuery;
    case "UpdateCheckout":
      return UpdateCheckout;
    case "OrdersByOrderNumber":
      return OrdersByOrderNumber;
    case "MenuByCustomSection":
      return MenuByCustomSection;
    default:
      return false;
  }
};

/**
 * Fuzzy search to get address suggestions.
 * @param {string} query URI encoded string to query.
 * @returns {Promise|error}
 */

export const getAddressSuggestions = async (query) => {
    const requestOptions = {
        method: "GET",
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `https://api.tomtom.com/search/2/search/${query}.json?key=${config.TOMTOMAPI}&countrySet=US,CA&idxSet=PAD,Addr`,
            requestOptions
        );
        return await response.json();
    } catch (error) {
        return console.log("error", error);
    }
};
