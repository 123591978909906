import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

const ErrorComponent = ({ error, componentStack, resetError }) => {
    const [eventId, setEventId] = useState(null);

    useEffect(() => {
        // Capture the error and store the eventId
        Sentry.withScope((scope) => {
            scope.setExtra('componentStack', componentStack);
            scope.setExtra('errorLocation', window.location.href);
            scope.setExtra('timestamp', new Date().toISOString());
            
            // Capture the event and get its ID directly
            const eventId = Sentry.captureException(error);
            console.log('Captured error with eventId:', eventId); // Debug log
            setEventId(eventId);
        });
        
        console.error(error);
    }, [error, componentStack]);

    const handleReset = () => {
        try {
            localStorage.removeItem('HEADLESS_AGE_GATE_PASSED');
            sessionStorage.clear();
            resetError();
        } catch (resetError) {
            console.error('Failed to reset error boundary:', resetError);
            window.location.reload();
        }
    };

    // const handleReportClick = () => {
    //     // Get both IDs for debugging
    //     const currentEventId = eventId;
    //     const lastEventId = Sentry.lastEventId();
    //     console.log('Current eventId:', currentEventId);
    //     console.log('Last eventId:', lastEventId);
        
    //     const reportId = currentEventId || lastEventId;
        
    //     if (reportId) {
    //         console.log('Showing report dialog with eventId:', reportId);
    //         try {
    //             Sentry.showReportDialog({
    //                 eventId: reportId,
    //                 title: 'Something went wrong',
    //                 subtitle: 'Our team has been notified.',
    //                 subtitle2: 'If you\'d like to help, tell us what happened below.',
    //                 // Add these required fields
    //                 user: {
    //                     name: 'Anonymous',
    //                     email: 'none@example.com'
    //                 }
    //             });
    //         } catch (e) {
    //             console.error('Error showing report dialog:', e);
    //         }
    //     } else {
    //         console.error('No eventId available for report dialog');
    //     }
    // };

    return (
        <div className='headless-error-component'>
            <div className='error-component-title'>
                Unable to load area.
            </div>
        </div>
    );
};

export default ErrorComponent;
